<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    en: {
    },
    pt: {
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      Value: "Valor",
      Level: "Nível",
      Bonus: "Bônus",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      Value: "Valor",
      Level: "Nivel",
      Bonus: "Bonus",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, VclList, Stat },
  data() {
    return {
      ads: {
        status: null,
        url: null,
      },

      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    getAds() {
      api
        .get("stakes/ads")
        .then((response) => {
          if (response.data.status == "success") {
            this.ads.status = response.data.ads.status
            this.ads.url = response.data.ads.url
          }
        });
    },
    getInvoices() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("stakes/invoices")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;

            this.statData = [
              {
                title: "Total de Stake",
                value: this.$options.filters.currency(response.data.total.stakes),
              }, {
                title: "Total de Revshare",
                value: this.$options.filters.currency(response.data.total.revshare),
              },
            ];
          } else if (response.data.status == "empty") {
            this.statData = [];
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getAds();
    this.getInvoices();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div>
            <h4 class="mb-0 font-size-18">{{ t("Stakes") }}</h4>
          </div>
          <div class="ml-3">
            <router-link :to="'/stakes/create'" class="btn btn-default btn-sm">
              Comprar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div v-if="ads && ads.status && ads.url" class="card">
      <div class="ads card-body py-2 d-flex align-items-center" :class="ads.status">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Atividade diária
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="this.ads.url">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="this.ads.url" v-on:click="$toast.success('Copiado!')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
        <div class="mt-1 ml-2 flex-fill text-right">
          <div v-if="ads.status == 'pending'">
            <i class="bx bxs-timer font-size-22 text-danger"></i>
          </div>
          <div v-else-if="ads.status == 'approved'">
            <i class="bx bxs-check-square font-size-22 text-success"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Revshare</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td>
                      <div class="d-flex align-items-center">
                        <div>
                          <img style="height: 40px;" :src="require('@/assets/images/stakes/' + td.cover)" />
                        </div>
                        <div class="ml-2">
                          <strong>{{ td.type }}</strong><br>
                          {{ td.value | currency }}<br>
                          {{ td.days }} dias
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ td.date.approved }}<br>
                      <span v-if="td.status === 'approved'" class="badge badge-soft-success font-size-12">APROVADO</span>
                      <span v-else-if="td.status === 'finalized'" class="badge badge-soft-warning font-size-12">ENCERRADO</span>
                    </td>
                    <td>{{ td.revshare | currency }}</td>
                    <td class="d-none text-right">
                      <router-link :to="'/stakes'" class="btn btn-default btn-sm btn-rounded">
                        Ver
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
td {
  line-height: 18px !important;
}

.ads.pending {
  border: solid 2px #ff0000;
  border-radius: 3px;
  background: #ff000020;
}

.ads.approved {
  border: solid 2px #34C392;
  border-radius: 3px;
  background: #34C39220;
}
</style>